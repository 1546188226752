<template>
  <div class="content-login">
    <div class="content-login-img">
      <img class="img-login-left" src="@/assets/ImgsLogin/Img-Login-Left.png" />
    </div>
    <div class="content-login-form" :style="{ height: screenHeight + 'px' }">
      <form-login-component />
    </div>
  </div>
</template>
<script>
import FormLoginComponent from "./FormLoginComponent.vue";
export default {
  components: { FormLoginComponent },
  data() {
    return { screenHeight: 0, sOrientation: null };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.screenHeight = window.innerHeight
     if (window.innerHeight > 470) {
       this.sOrientation = false
     }else{
       this.sOrientation = true

     }
      // if (window.innerWidth < 599) {
      //   this.screenHeight = window.innerHeight;
      // } else if (window.innerWidth > 600 && window.innerWidth < 959) {
      //   this.screenHeight = window.innerHeight - 350;
      // } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
      //   this.screenHeight = window.innerHeight - 350;
      // } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
      //   this.screenHeight = window.innerHeight;
      // } else if (window.innerWidth > 1904) {
      //   this.screenHeight = window.innerHeight;
      // }
    },
  },
};
</script>
<style scoped>
.content-login {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.content-login-img {
  display: flex;
  width: 60vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  /* background-color: brown; */
}
.img-login-left {
  padding: 0px 0px 0px 45px;
  width: 60vw;
  max-width: 60vw;
  min-width: 60vw;
  height: 90vh;
  max-height: 90vh;
  min-height: 90vh;
  justify-content: center;
  object-fit: cover;
}
.content-login-form {
  overflow: auto;
  width: 40vw;
  /* height: 100vh; */
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  /* background-color: burlywood; */
}

/*#region scroll-login-form */
.content-login-form::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.content-login-form::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-login-form::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-login-form::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

/*#endregion scroll-login-form*/

/* RESPONSIVE CSS */

/* XS Small to large phone*/
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-login {
    display: block;
    width: 100vw;
    height: 100vh;
  }
  .content-login-img {
    display: none;
    width: 60vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .img-login-left {
    display: none;
    width: 100vw;
    max-width: 90vw;
    min-width: 90vw;
    height: 90vh;
    max-height: 90vh;
    min-height: 90vh;
    justify-content: center;
    object-fit: cover;
  }
  .content-login-form {
    width: 100vw;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
}
/* SM Small to medium tablet */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }

  .content-login {
    display: block;
    width: 100vw;
    height: 100vh;
  }
  .content-login-img {
    display: none;
    width: 60vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .img-login-left {
    display: none;
    width: 100vw;
    max-width: 90vw;
    min-width: 90vw;
    height: 90vh;
    max-height: 90vh;
    min-height: 90vh;
    justify-content: center;
    object-fit: cover;
  }
  .content-login-form {
    width: 100vw;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
}
/* MD Large tablet to laptop */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-login {
    display: block;
    width: 100vw;
    height: 100vh;
  }
  .content-login-img {
    display: none;
    width: 60vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .img-login-left {
    display: none;
    width: 100vw;
    max-width: 90vw;
    min-width: 90vw;
    height: 90vh;
    max-height: 90vh;
    min-height: 90vh;
    justify-content: center;
    object-fit: cover;
  }
  .content-login-form {
    width: 100vw;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
}
/* LG Desktop */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/* XL  4k and ultra-wide */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>