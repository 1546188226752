<template>
  <div class="container-form-login">
    <div class="content-form-login">
      <div class="content-form-login-img">
        <img class="img-form" src="@/assets/ImgsLogin/Icon-Login.png" />
      </div>
      <div class="content-form-login-text-field">
        <v-form>
          <div class="content-field-email">
            <p class="lbl-email">Correo electrónico</p>
            <v-text-field
              v-model="sEmail"
              ref="textEmail"
              placeholder="Ingrese correo electrónico"
              autocomplete="off"
              prepend-icon="mdi-email-outline"
              class="global-text-field"
              label=""
              persistent-placeholder
              @keypress="focusTextPassowrd"
            >
            </v-text-field>
          </div>
          <div class="content-field-password">
            <p class="lbl-password">Contraseña</p>

            <v-text-field
              v-model="sPassword"
              ref="textPass"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              prepend-icon="mdi-lock-outline"
              placeholder="Ingrese contraseña"
              @click:append="showPassword = !showPassword"
              class="global-text-field"
              label=""
              autocomplete="new-password"
              persistent-placeholder
              @keypress="preLogin"
            >
            </v-text-field>
          </div>
        </v-form>
      </div>
      <div class="content-form-login-btns-accions">
        <div class="content-btn-primary">
          <v-btn
            class="global-btn-primary"
            :loading="loadingForm"
            @click="login()"
            :disabled="!validateForm"
          >
            Iniciar sesión
          </v-btn>
        </div>
      </div>
    </div>
    <div class="content-footer-powered-by">
      <span class="footer-powered-by">Powered by </span>
      <span
        @click="openSofex"
        class="footer-sofex-technologies text-decoration-underline"
        >Sofex Technologies</span
      >
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      loadingForm: false,
      sEmail: "",
      sPassword: "",
      showPassword: false,
    };
  },
  mounted() {},
  methods: {
    openSofex() {
      window.open("https://www.sofex.com.mx");
    },
    login() {
      this.loadingForm = true;
      if (this.sEmail !== "" && this.sPassword !== "") {
        const payload = {
          sEmail: this.sEmail,
          sPassword: this.sPassword,
        };
        window.DB.post(`${window.URI}/auth/login`, payload)
          .then((response) => {
            this.loadingForm = false;

            this.$store.commit("setUserLogin", {
              sUserId: response.data.user.sUserId,
              sName: "Maigs",
              sEmail: response.data.user.sEmail,
              sToken: response.data.token,
            });
            this.Success(response.data.message);
            setTimeout(() => {
              this.$router.go();
            }, 1000);
          })
          .catch((error) => {
            this.loadingForm = false;
            this.sEmail = "";
            this.sPassword = "";
            this.Error(0, error.response.data.message);
          });
      }
    },
    preLogin: function ($event) {
      if ($event.code == "Enter") this.login();
    },
    focusTextPassowrd(e) {
      var keycode = e.keyCode ? e.keyCode : e.which;
      if (keycode == "13") {
        this.$refs.textPass.focus();

        e.preventDefault();
        return false;
      }
    },
  },
  computed: {
    validateForm() {
      return this.sEmail.trim() !== "" && this.sPassword.trim() !== "";
    },
  },
};
</script>
<style scoped>
.img-form {
  width: 178px;
  height: 178px;
  margin-bottom: 10px;
}
.container-form-login {
  overflow: auto;
  position: relative;
  display: flex;
  place-items: center;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  min-height: 500px !important;
}
.content-form-login {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  padding: 0px 100px 0px 100px;
}
.content-field-email {
  margin-bottom: 29px;
}
.content-field-email .global-text-field {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.lbl-email {
  margin-bottom: 0px;
  margin-left: 33px;
  text-align: start;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-text-label) !important;
  font-size: 20px;
}
.content-field-password {
  margin-bottom: 29px;
}
.content-field-password .global-text-field {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.lbl-password {
  margin-bottom: 0px;
  margin-left: 33px;
  text-align: start;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-text-label) !important;
  font-size: 20px;
}
.content-footer-powered-by {
  position: absolute;
  bottom: 10px;
  left: 0px;
  right: 0px;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-border-grey);
}
.footer-powered-by {
  color: var(--primary-color-border-grey);
}
.footer-sofex-technologies {
  cursor: pointer;
  color: var(--primary-color-text-link) !important;
}

/* RESPONSIVE CSS */

/* XS Small to large phone*/
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .img-form {
    width: 168px;
    height: 168px;
  }

  .content-form-login {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    padding: 0px 25px 0px 25px;
  }
}
/* SM Small to medium tablet */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }
  .content-form-login {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    padding: 0px 150px 0px 150px;
  }
}
/* MD Large tablet to laptop */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
  .content-form-login {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    padding: 0px 250px 0px 250px;
  }
}
/* LG Desktop */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/* XL  4k and ultra-wide */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>