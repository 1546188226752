<template>
    <div class="content-global">
        <login-component/>
      <!-- <div class="demoColorResponsive"></div> -->

    </div>
</template>
<script>
import LoginComponent from '../components/login/LoginComponent.vue'
export default {
  components: { LoginComponent },
    data() {
      return{

      }  
    },
}
</script>
<style scoped>
.content-global{
  height: 100vh !important;

}
/* RESPONSIVE CSS */

/* XS Small to large phone*/
@media (max-width: 599px) {
    .demoColorResponsive {
      background-color: rgb(190, 136, 226);
    }
  }
  /* SM Small to medium tablet */
  @media (min-width: 600px) and (max-width: 959px) {
  }
  /* MD Large tablet to laptop */
  @media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
      background-color: rgb(201, 122, 83);
    }
  }
  /* LG Desktop */
  @media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
      background-color: rgb(204, 198, 109);
    }
  }
  /* XL  4k and ultra-wide */
  @media (min-width: 1904px) {
    .demoColorResponsive {
      background-color: rgb(113, 199, 201);
    }
  }
</style>